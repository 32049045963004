import { render, staticRenderFns } from "./Slide.vue?vue&type=template&id=24da6785&scoped=true"
import script from "./Slide.vue?vue&type=script&lang=js"
export * from "./Slide.vue?vue&type=script&lang=js"
import style0 from "./Slide.vue?vue&type=style&index=0&id=24da6785&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24da6785",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveImage: require('/usr/src/app/components/responsive-image/ResponsiveImage.vue').default})
